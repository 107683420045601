import ResponsiveBox from 'components/layout/ResponsiveBox';
import styled from 'styled-components';
import { desktop, tablet } from 'utils/media';
import theme from 'utils/theme';

export const StyledResponsiveBox = styled(ResponsiveBox)({
  backgroundColor: theme.colors.white,
  borderRadius: 40,
  [desktop]: {
    borderRadius: 80,
  },
});

export const TitleRow = styled.div({
  marginBottom: 40,
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  alignItems: 'normal',
  padding: '0 20px',
  color: theme.colors.purple,

  [tablet]: {
    alignItems: 'center',
    flexDirection: 'row',
    padding: '0 40px',
  },
});

export const Label = styled.div({
  marginTop: 16,
  fontWeight: 'bold',
  fontSize: 14,
  lineHeight: 1.5,
  color: theme.colors.black,
  [tablet]: {
    fontSize: 16,
  },
});

export const Price = styled.div({
  color: theme.colors.darkGreen,
});

interface TabLinkProps {
  active: boolean;
}

export const TabLink = styled.button<TabLinkProps>((props) => ({
  appearance: 'none',
  border: 0,
  padding: 0,
  fontSize: 16,
  margin: '0 20px 20px 0',
  background: 'transparent',
  fontWeight: 'bold',
  color: theme.colors.black,
  opacity: props.active ? 1 : 0.4,
  cursor: 'pointer',
  '&:focus': {
    outline: 'none',
  },
  ...(props.active
    ? {}
    : {
        '&:hover, &:focus': {
          opacity: 0.5,
        },
      }),
}));

export const TabsRow = styled.div({
  marginBottom: -20,
});
