import Container from 'components/layout/Container';
import Link from 'next/link';
import React from 'react';
import { desktop, mobile, tablet } from 'utils/media';
import Image from 'components/Image';
import MaskedIcon from 'components/MaskedIcon';
import { SanityImageAsset } from 'utils/types';
import { CarouselRow, CarouselItem } from 'components/layout/Carousel';
import { Heading2 } from 'components/text';
import {
  StyledLink,
  StyledResponsiveBox,
  MaskedIconWrapper,
  Label,
  TitleRow,
  ShortcutGridSection,
} from './styles';

export interface ShortcutProps {
  _key: string;
  _type: 'shortcut';
  name?: string;
  path?: string;
  image?: string;
  icon?: SanityImageAsset;
}

export interface ShortcutGridProps {
  title?: string;
  shortcuts?: ShortcutProps[];
}

export default function ShortcutGrid({
  title,
  shortcuts = [],
}: ShortcutGridProps) {
  return (
    <ShortcutGridSection bg="light">
      <Container noGutter noPadding>
        {title ? (
          <TitleRow>
            <Heading2 inline>{title}</Heading2>
          </TitleRow>
        ) : null}
        <CarouselRow>
          {shortcuts.map((shortcut) => (
            <CarouselItem key={shortcut._key}>
              <Link href={shortcut.path || '/'} passHref>
                <StyledLink>
                  <StyledResponsiveBox aspectRatio={3 / 4}>
                    {shortcut.icon ? (
                      <MaskedIconWrapper>
                        <MaskedIcon
                          asset={shortcut.icon}
                          size={{ [mobile]: 48, [tablet]: 48, [desktop]: 64 }}
                        />
                      </MaskedIconWrapper>
                    ) : null}
                    {shortcut.image ? (
                      <Image
                        src={shortcut.image}
                        aspectRatio={3 / 4}
                        sizes={`${mobile} 164px, 224px`}
                        alt=""
                      />
                    ) : null}
                  </StyledResponsiveBox>
                  <Label>{shortcut.name}</Label>
                </StyledLink>
              </Link>
            </CarouselItem>
          ))}
        </CarouselRow>
      </Container>
    </ShortcutGridSection>
  );
}
