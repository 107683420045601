import styled from 'styled-components';
import { desktop, mobile, tablet } from 'utils/media';
import MaskedIcon from 'components/MaskedIcon';
import { Heading4, Heading2 } from 'components/text';
import theme from 'utils/theme';
import ResponsiveBox from 'components/layout/ResponsiveBox';
import { WrapperProps, BannerProps, BannerAlignment } from './types';

const isOverlay = (alignment: string | undefined) =>
  alignment === 'leftOverlay' ||
  alignment === 'rightOverlay' ||
  alignment === 'centerOverlay';

export const Banner = styled.div<BannerProps>((props) => ({
  position: 'relative',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  overflow: 'hidden',
  backgroundImage: props.bannerUrl ? `url(${props.bannerUrl})` : 'none',
  flex: '50%',
  order: props.alignment === 'left' ? 1 : 0,
  ...(isOverlay(props.alignment)
    ? {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }
    : {
        [mobile]: {
          position: 'relative',
          height: 0,
          paddingTop: '64%',
        },
      }),
}));

export const TwoColWrapper = styled.div<WrapperProps>((props) => ({
  position: 'relative',
  textAlign: 'left',
  display: 'block',
  backgroundColor:
    props.backgroundColor && props.backgroundColor in theme.colors
      ? theme.colors[props.backgroundColor]
      : '#fff',
  color: props.textColor ? theme.colors[props.textColor] : 'inherit',
  marginBottom: props.hasPadding ? 30 : 0,
  zIndex: 1,
  '::before': {
    display: 'block',
    content: "''",
    width: 1,
    marginLeft: -1,
    float: 'left',
    height: 0,
    paddingTop: '85%',
    [tablet]: {
      paddingTop: '33.333%',
    },
  },
  '::after': {
    display: 'table',
    clear: 'both',
    content: "''",
  },
  [tablet]: {
    display: 'flex',
  },
}));

interface TextSectionProps {
  alignment?: BannerAlignment;
  darkenOverlay?: boolean;
}

export const TextSection = styled.div<TextSectionProps>((props) => ({
  display: 'flex',
  position: 'relative',
  flex: '50%',
  alignItems: 'flex-end',
  padding: '40px 20px',
  zIndex: 1,
  [tablet]: {
    padding: 40,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  [desktop]: {
    padding: '40px 80px 40px 40px',
  },
  ...(isOverlay(props.alignment)
    ? {
        background: props.darkenOverlay ? theme.colors.iconUnderlay : 'none',
        ...(props.alignment === 'rightOverlay'
          ? { [tablet]: { justifyContent: 'flex-end' } }
          : {}),
        '> div': {
          [tablet]: {
            width: '50%',
            ...(props.alignment === 'centerOverlay'
              ? { margin: '0 auto', textAlign: 'center' }
              : {}),
          },
        },
      }
    : {}),
}));

export const ButtonWrapper = styled.div({ marginTop: 32 });

export const LabelWrapper = styled.div({ marginBottom: 24 });

export const StyledHeading4 = styled(Heading4)({
  lineHeight: 1,
});

export const StyledMaskedIcon = styled(MaskedIcon)({
  marginRight: 5,
});

interface OverlayResponsiveBoxProps {
  textColor?: Sproutl.ColorScheme;
  hasPadding?: boolean;
}

export const OverlayResponsiveBox = styled(
  ResponsiveBox,
)<OverlayResponsiveBoxProps>((props) => ({
  display: 'flex',
  color: props.textColor ? theme.colors[props.textColor] : 'inherit',
  marginBottom: props.hasPadding ? 30 : 0,
}));

export const DesktopLargerHeading2 = styled(Heading2)`
  ${desktop} {
    font-size: 80px;
    line-height: 86px;
  }
`;
